
















import { Component, Prop, Vue } from "vue-property-decorator";
import { BaseEntry } from "@sokkuri/common";
import EntryUtils from "@/common/utilities/EntryUtils";
import ImageComponent from "@/components/global/ImageComponent.vue";
import StringUtils from "@/common/utilities/StringUtils";

@Component({
    components: {
        ImageComponent
    }
})
export default class GridCardComponent extends Vue {
    @Prop({ required: true }) private entry: BaseEntry;
    @Prop({ required: true }) private entryType: string;
    @Prop({ required: true }) private columnWidth: number;

    private getTitle(shorten: boolean) {
        const title = EntryUtils.getTitle(this.entry.titles).title;

        if (title.length > 50 && shorten) {
            return `${StringUtils.shorten(title, 40)} ...`;
        }

        return title;
    }

    private getColumnWidth() {
        return `column is-${this.columnWidth}`;
    }
}
